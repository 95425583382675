const iconSize = (size?: number) => (size ? `width="${size}" height="${size}"` : '');

const svgStyleString = (style?: any) => {
  let styleStr = '';
  if (style['size']) {
    styleStr += `width='${style['size']}' height='${style['size']}' `;
  }
  if (style['color']) {
    styleStr += `color='${style['color']}' `;
  }

  return styleStr;
};

export const boldIcon = (size?: number) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${iconSize(size)} viewBox='0 0 40 40'>
        <path d='M920.976,3868.04a1,1,0,0,0-.728.23,0.936,0.936,0,0,0-.249.73,4.367,4.367,0,0,0,.3,1.53h1.888c0.066,1.56.1,6.29,0.1,11.1,0,5.33-.033,10.33-0.1,11.9h-1.209a1.05,1.05,0,0,0-.728.23,0.927,0.927,0,0,0-.249.72,4.473,4.473,0,0,0,.3,1.55l7.964-.02c4.348,0,7.392-.68,9.131-2.02s2.608-4.62,2.608-6.69q0-4.575-5.746-5.76c3.069-1.4,4.6-3.44,4.6-6.14,0-1.95-.814-4.96-2.442-5.92s-4.114-1.44-7.458-1.44h-7.981Zm6.424,15.25a13.831,13.831,0,0,1,2.185-.18,5.042,5.042,0,0,1,3.552,1.07,4.114,4.114,0,0,1,1.151,3.15c0,1.29-.318,3.77-0.953,4.33a4.469,4.469,0,0,1-3.037.85c-1.336,0-2.153-.22-2.451-0.65a15.5,15.5,0,0,1-.447-4.31v-4.26Zm0-3.33c0-3.31.006-7.09,0.017-8.24a11.616,11.616,0,0,1,2-.19,3.874,3.874,0,0,1,2.8.9c0.618,0.59.927,3.08,0.927,4.34a3.362,3.362,0,0,1-.869,2.56,4.121,4.121,0,0,1-2.856.81,9.672,9.672,0,0,1-2.02-.18h0Z' transform='translate(-910 -3862.03)'/>
    </svg>`;
export const italicIcon = (size?: number) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${iconSize(size)} viewBox='0 0 40 40'>
        <path d='M995.992,3870.37v-0.93a1.383,1.383,0,0,0-1.332-1.42h-9.327a1.383,1.383,0,0,0-1.332,1.42v1.43a1.383,1.383,0,0,0,1.332,1.42,1.853,1.853,0,0,1,1.732,2.28l-2.4,15.63a1.869,1.869,0,0,1-1.732,1.57h-1.6a1.383,1.383,0,0,0-1.332,1.42v1.42a1.383,1.383,0,0,0,1.332,1.42h9.327a1.383,1.383,0,0,0,1.332-1.42v-1.42a1.383,1.383,0,0,0-1.332-1.42,1.853,1.853,0,0,1-1.732-2.28l2.4-15.64a1.869,1.869,0,0,1,1.732-1.56h1.133a1.852,1.852,0,0,0,1.8-1.92h0Z' transform='translate(-968 -3862.03)'/>
    </svg>`;
export const underlineIcon = (size?: number) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${iconSize(size)} viewBox='0 0 40 40'>
        <path d='M1038.63,3870.97h-0.35a3.536,3.536,0,0,1-1.79-.32,1.273,1.273,0,0,1-.49-1.15,1.412,1.412,0,0,1,.41-1.11,1.854,1.854,0,0,1,1.24-.36h5.44a1.527,1.527,0,0,1,1.62,1.47,1.613,1.613,0,0,1-1.62,1.47h-0.94v6.53a16.981,16.981,0,0,0,.23,3.29,6.03,6.03,0,0,0,.75,1.89,5.165,5.165,0,0,0,2.12,1.94,6.542,6.542,0,0,0,3.01.7,5.5,5.5,0,0,0,4.53-1.76q1.425-1.755,1.43-5.68v-6.91h-1.16a1.693,1.693,0,0,1-1.75-1.47,1.342,1.342,0,0,1,1.25-1.47h5.85a1.7,1.7,0,0,1,1.19.37,1.451,1.451,0,0,1,.4,1.1,1.292,1.292,0,0,1-.48,1.15,3.392,3.392,0,0,1-1.74.32h-0.3v7.36q0,4.965-2.36,7.44c-1.57,1.66-3.94,2.48-7.1,2.48a9.4,9.4,0,0,1-6.84-2.48,8.884,8.884,0,0,1-2.55-6.68v-8.12Zm0.1,21.95h18.54a1.557,1.557,0,1,1,0,3.11h-18.54A1.557,1.557,0,1,1,1038.73,3892.92Z' transform='translate(-1028 -3862.03)'/>
    </svg>`;
export const olIcon = (size?: number) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${iconSize(size)} viewBox='0 0 40 40'>
        <path d='M1351.6,3897.01h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1351.6,3897.01Zm0-13h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1351.6,3884.01Zm0-13h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1351.6,3871.01Zm-25.68,28c-1.46,0-2.92-.63-2.92-2.22h2.02a0.775,0.775,0,0,0,.9.74,0.767,0.767,0,0,0,.9-0.75,0.838,0.838,0,0,0-.94-0.75h-0.28v-1.41h0.28a0.719,0.719,0,0,0,.84-0.67,0.814,0.814,0,0,0-1.6-.01h-2.02c0-1.33,1.25-2.14,2.82-2.14,1.62,0,2.82.88,2.82,2.1a1.482,1.482,0,0,1-.92,1.39,1.684,1.684,0,0,1,1.01,1.54C1328.83,3898.31,1327.46,3899.01,1325.92,3899.01Zm-2.92-14.48,3.29-2.8a0.954,0.954,0,0,0,.45-0.77,0.685,0.685,0,0,0-.81-0.66,0.716,0.716,0,0,0-.84.69H1323c0-1.4,1.35-2.17,2.93-2.17,1.68,0,2.9.81,2.9,2.15a2.183,2.183,0,0,1-1.12,1.76l-2.16,1.8h3.28v1.49H1323v-1.49Zm2.13-16.96h-1.3v-1.58H1328V3874h-2.87v-6.43Z' transform='translate(-1318 -3862.5)'/>
    </svg>`;
export const ulIcon = (size?: number) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${iconSize(size)} viewBox='0 0 40 40'>
        <path d='M1430.6,3897.01h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1430.6,3897.01Zm0-13h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1430.6,3884.01Zm0-13h-19.21a1.4,1.4,0,0,1-1.4-1.4v-0.21a1.4,1.4,0,0,1,1.4-1.4h19.21a1.4,1.4,0,0,1,1.4,1.4v0.21A1.4,1.4,0,0,1,1430.6,3871.01ZM1404.5,3898a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,1404.5,3898Zm0-13a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,1404.5,3885Zm0-13a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,1404.5,3872Z' transform='translate(-1397 -3862.5)'/>
    </svg>`;

export const likeIcon = (style?: any) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${svgStyleString(style)} viewBox='0 0 48 48'>
        <path fill='currentColor' d='M24.000,-0.002 C27.960,-0.002 31.200,3.238 31.200,7.197 L31.200,14.397 L45.599,14.397 C46.919,14.397 47.999,15.477 47.999,16.797 L47.999,35.997 C47.999,42.597 42.599,47.996 35.999,47.996 L2.400,47.996 C1.080,47.996 0.000,46.917 0.000,45.597 L0.000,16.797 C0.000,15.477 1.080,14.397 2.400,14.397 L10.680,14.397 L18.360,1.197 C18.840,0.478 19.560,-0.002 20.400,-0.002 L24.000,-0.002 ZM43.199,19.197 L28.800,19.197 C27.480,19.197 26.400,18.117 26.400,16.797 L26.400,7.197 C26.400,5.877 25.320,4.798 24.000,4.798 L21.720,4.798 L14.400,17.397 L14.400,43.197 L35.999,43.197 C39.959,43.197 43.199,39.957 43.199,35.997 L43.199,19.197 ZM4.800,43.197 L9.600,43.197 L9.600,19.197 L4.800,19.197 L4.800,43.197 Z'/>
    </svg>`;
export const replyIcon = (style?: any) =>
  `<svg xmlns='http://www.w3.org/2000/svg' ${svgStyleString(style)} viewBox='0 0 54 54'>
        <path fill='currentColor' d='M27,54A27,27,0,1,1,54,27v0.137a27.016,27.016,0,0,1-2.657,11.557l1.651,6.612a6.447,6.447,0,0,1-7.534,7.885l-7.454-1.53A26.739,26.739,0,0,1,27,54ZM27,5.094a21.906,21.906,0,0,0,0,43.813,21.666,21.666,0,0,0,9.49-2.157l0.862-.422L46.48,48.2a1.338,1.338,0,0,0,1.233-.387,1.365,1.365,0,0,0,.339-1.27l-2.116-8.476,0.531-1.008a21.871,21.871,0,0,0,2.439-9.932V27A21.931,21.931,0,0,0,27,5.094Z'/>
    </svg>`;
export const doneIcon = (style?: any) =>
  `<svg xmlns="http://www.w3.org/2000/svg" ${svgStyleString(style)} viewBox="0 0 48 48">
  <path fill='currentColor' d="M42.443,8.545a2.175,2.175,0,0,0-2.964,0L17.853,29.509,8.511,20.456a2.168,2.168,0,0,0-2.964,0L2.583,23.287a1.94,1.94,0,0,0-.026,2.8l0.026,0.025L13.4,36.57,16.362,39.4a2.176,2.176,0,0,0,2.964,0l2.964-2.831L35.176,23.992l10.232-9.78a1.949,1.949,0,0,0,.019-2.817l-0.019-.018L42.443,8.545h0Z"/>
</svg>`;
